.container {
    background-color: #7A1529;
    padding-bottom: 5px;
    
    .logoContainer {
        background-color: #ffffff;
        .headerLogo {
            max-height: 120px;
        }
    }
    

    .innerContainer {
        margin: 0 auto;
        width: 80%;

        .profilePhoto {
            width: 100%;
        }

        .fullName {
            color:white;
            font-size: 25px;
            margin-bottom: 8px;
        }

        .labelText {
            color:white;
            font-size: 20px;
            margin-bottom: 5px;
        }

        .text {
            color:white;
            font-size: 18px;
            margin-top: 5px;
            margin-bottom: 5px; 
        }

        .qrContainer {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
        }
    }
}