.container {
    background-color: #000000;
    height: 100vh;
    display: flex;
    flex-direction: column;
    .logoContainer {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .logo {
            max-height: 120px;
        }
    }
    .innerContainer {
        flex-grow: 2;
        display: flex;
        flex-direction: column;
        width: 90%;
        margin: 0 auto;
        .text {
            color:white;
            font-size: 18px;
            margin-top: 5px;
            margin-bottom: 15px; 
        }

        .input {
            background-color: transparent;
            border: none;
            border-bottom: 1.5px #ffffff solid;
            padding: 2px;
            color: white;
            font-size: 18px;
        }

        .button {
            margin-top: 30px;
            color: white;
            background-color: transparent;
            padding: 8px;
            border-radius: 8px;
            font-size: 18px;
        }
    }

    .privacyPolicy {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        margin-bottom: 20px;
        a {
            color: white;
            text-decoration: none;
            font-weight: 500;
        }
    }
    
}